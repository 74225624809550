<template>
  <project-summary :project="prevFormData" :edit="edit" />
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ProjectSummary from "@/components/projects/ProjectSummary";

export default {
  name: "Confirmation",
  components: {
    ProjectSummary,
  },
  mounted() {
    this.setInProgress(this.stepIndex);
    this.prevFormData = { ...this.getPrevFormData };
    let status = this.getProject.status.id;
    if (status <= 2) {
      this.edit = true;
    } else {
      this.edit = false;
    }
  },
  updated() {
    if (this.valid) {
      this.setComplete(this.stepIndex);
      this.setStepData({
        index: this.stepIndex,
        data: { ...this.formData },
      });
    } else {
      this.setInProgress(this.stepIndex);
    }
  },
  data() {
    return {
      stepIndex: 3,
      prevFormData: null,
      edit: false,
      formData: {
        informationDeclaration: false,
        authorizationDeclaration: false,
        termsAndConditions: false,
      },
      //valid: false
      valid: true,
    };
  },
  methods: {
    ...mapActions("createProject", ["setInProgress", "setComplete", "setStepData"]),
    goToStep(step) {
      this.$router.push(step);
    },
  },
  computed: {
    ...mapGetters({
      getPrevFormData: "createProject/getFormData",
      getProject: "createProject/getProject",
    }),
  },
};
</script>

<style scoped>
.v-application .primary--text {
  caret-color: #7319d5;
  color: #7319d5 !important;
}

.text-profile {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #757575;
}

.terms-and-conditions >>> .v-label {
  color: rgba(0, 0, 0, 0.87) !important;
}

.edit-icon {
  cursor: pointer;
}
</style>
